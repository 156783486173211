import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LineNotifyCallback = () => {
  const { t } = useTranslation();
  const { functions, user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const error = params.get('error');
    if (error) {
      alert(`Callback error: ${error}`);
    } else {
      const code = params.get('code');
      const state = params.get('state');
      if (user.id === state) {
        functions
          .connectLine({
            code: code
          })
          .then(response => {
            console.log(response.data);
            if (response.data && response.data.status === 200) {
              setErrorMessage(JSON.stringify(response.data));
              navigate('/user/settings', { replace: true });
            } else {
              setIsLoading(false);
              setErrorMessage(JSON.stringify(response.data));
            }
          });
      } else {
        setIsLoading(false);
        setErrorMessage('Wrong User!');
      }
    }
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={t('lineNotify')} />
      <Card.Body className="bg-light">
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center bg-light w-100 h-100 opacity-75">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!isLoading && <>{errorMessage}</>}
      </Card.Body>
    </Card>
  );
};

export default LineNotifyCallback;
