import React from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
// import avatar from 'assets/img/team/2.jpg';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';
import useAuth from 'hooks/useAuth';
import LineNotify from './LineNotify';
import LanguageSettings from './LanguageSettings';

const Settings = () => {
  const { user } = useAuth();
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={user.photoURL}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={6}>
          <Row className="g-0 mb-3">
            <ProfileSettings />
          </Row>
          <Row className="g-0 ">
            <LineNotify />
          </Row>
        </Col>
        <Col lg={6}>
          <Row className="g-0 mb-3">
            <LanguageSettings />
          </Row>
          <Row className="g-0">
            <div className="sticky-sidebar">
              <ChangePassword />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
