import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

const ProfileSettings = () => {
  const { user, firestore } = useAuth();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    displayName: user.displayName
  });
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    firestore.setUserDisplayName(formData.displayName).then(() => {
      window.location.reload();
    });
  };

  return (
    <Card>
      <FalconCardHeader title={t('profileSettings')} />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="Display Name">
              <Form.Label>{t('displayName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Display Name"
                value={formData.displayName}
                name="displayName"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button variant="primary" type="submit">
              {loading ? (
                <Spinner
                  animation="border"
                  variant="white"
                  className=""
                  size="sm"
                />
              ) : (
                t('update')
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
