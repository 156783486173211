import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CopyableTextBox = ({ text }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const textRef = useRef(null);

  const handleCopy = () => {
    if (textRef.current) {
      navigator.clipboard
        .writeText(textRef.current.textContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch(error => {
          console.error('Failed to copy text: ', error);
        });
    }
  };

  return (
    <div className="d-inline-block">
      <div
        className="d-inline-block px-3 py-2 m-2"
        ref={textRef}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#f9f9f9',
          userSelect: 'all', // Allow user to select text for manual copy
          wordWrap: 'break-word', // Break long words
          maxWidth: '500px' // Set a maximum width
        }}
      >
        {text}
      </div>
      <Button className="mb-1" variant="primary" onClick={handleCopy}>
        {copied ? t('copied') : t('copy')}
      </Button>
    </div>
  );
};

CopyableTextBox.propTypes = {
  text: PropTypes.string.isRequired
};

export default CopyableTextBox;
