import { faCheckCircle, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import CopyableTextBox from 'components/common/CopyableTextBox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Spinner, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import lineQrSrc from 'assets/img/line/vip-iot-plus-qr.png';

const LineNotify = () => {
  const { t } = useTranslation();
  const { functions, user, firestore } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [connectState, setConnectState] = useState(undefined);
  const [connectData, setConnectData] = useState(undefined);
  const [lineData, setLineData] = useState(undefined);

  useEffect(() => {
    setIsLoading(false);
    const unsubscribe = firestore.onUserlineProfileSnapshot(newLineData => {
      setLineData(newLineData);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (lineData && lineData.line_msg?.state === 'pending') {
      setConnectState('PENDING');
    } else if (lineData && lineData.line_msg?.state === 'connected') {
      setConnectState('CONNECTED');
    } else if (
      lineData === undefined ||
      lineData.line_msg?.state === undefined ||
      lineData.line_msg?.state === 'cancelled'
    ) {
      setConnectState('NOT_CONNECT');
    }
  }, [lineData]);

  const handleConnect = () => {
    setIsLoading(true);
    functions.generateConnectCode().then(res => {
      // console.log(res);
      setConnectData(res.data);
      setIsLoading(false);
      setConnectState('CODE_GENERATED');
    });
  };

  const handleCancel = () => {
    setIsLoading(true);
    functions
      .notifyLine({ message: 'Disconnect with ' + user.displayName })
      .then(() => {
        firestore
          .setLineConnectState('cancelled')
          .then(() => {
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
            return functions.notifyLine({
              message: "Can't disconnect with " + user.displayName
            });
          });
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleConfirm = () => {
    setIsLoading(true);
    firestore.setLineConnectState('connected').then(() => {
      functions
        .notifyLine({ message: 'Connected with ' + user.displayName })
        .then(() => {
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
        });
    });
  };

  const handleTest = () => {
    setIsLoading(true);
    const testMsg = '🎉Notification Test!🎉\nFrom ' + user.displayName;
    functions
      .notifyLine({ message: testMsg })
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <Card className="">
      <FalconCardHeader title={t('lineNotify')} />
      <Card.Body className="bg-light">
        {isLoading || connectState === undefined ? (
          <div className="d-flex justify-content-center align-items-center bg-light w-100 h-100 opacity-75">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : connectState === 'CODE_GENERATED' ? (
          <div className="text-center">
            <h4>{t('howToConnect')}</h4>
            <div className="text-800 mb-2">
              1. {t('lineConnectStep1A')}{' '}
              <a href="https://lin.ee/dV7po36">VIP IoT Plus</a>{' '}
              {t('lineConnectStep1B')} <br />
              <a href="https://lin.ee/dV7po36">https://lin.ee/dV7po36</a>
            </div>
            <div className="text-center mb-2">
              <Image className="w-50" src={lineQrSrc} />
            </div>
            <div className="text-800">
              2. {t('lineConnectStep2')}
              <br /> <a href="https://lin.ee/dV7po36">VIP IoT Plus</a>
            </div>
            <div className="text-center">
              {t('code')} <CopyableTextBox text={connectData?.code} />
            </div>
          </div>
        ) : connectState === 'CONNECTED' ? (
          <div className="text-center">
            <div className="text-success">
              {t('connected')}{' '}
              <FontAwesomeIcon className="text-success" icon={faCheckCircle} />{' '}
            </div>
            <Avatar
              size="3xl"
              className="mt-2"
              src={lineData?.line_msg?.profile?.pictureUrl}
              mediaClass="shadow-sm"
            />
            <h3>{lineData?.line_msg?.profile?.displayName}</h3>
            <Button
              variant="outline-primary"
              className="m-1"
              onClick={handleTest}
            >
              {t('sendATestMessage')}
            </Button>
            <Button
              variant="outline-danger"
              className="m-1"
              onClick={handleCancel}
            >
              {t('disconnect')}
            </Button>
          </div>
        ) : connectState === 'PENDING' ? (
          <div className="text-center">
            <div className="text-dark">
              <FontAwesomeIcon className="text-warning" icon={faUserCheck} />{' '}
              {t('please-confirm-line-account')}{' '}
            </div>
            <Avatar
              size="3xl"
              className="mt-2"
              src={lineData?.line_msg?.profile?.pictureUrl}
              mediaClass="shadow-sm"
            />
            <h3>{lineData?.line_msg?.profile?.displayName}</h3>
            <Button
              variant="outline-danger"
              className="m-1"
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
            <Button variant="primary" className="m-1" onClick={handleConfirm}>
              {t('confirm')}
            </Button>
          </div>
        ) : (
          <div className="text-center">
            <Button className="" onClick={handleConnect}>
              {t('connectToLineNotify')}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default LineNotify;
