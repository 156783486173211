import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
  onSnapshot
} from 'firebase/firestore';
import {
  getDatabase,
  ref,
  onValue,
  onChildAdded,
  startAfter,
  orderByKey,
  get,
  set
} from 'firebase/database';
import {
  getFunctions,
  httpsCallable
  // ,connectFunctionsEmulator
} from 'firebase/functions';
//
import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@minimals.cc'];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const FTDB = getFirestore(firebaseApp);

const RTDB = getDatabase(firebaseApp);

const FUNC = getFunctions(firebaseApp, 'asia-southeast1');

// connectFunctionsEmulator(FUNC, 'localhost', 5001);

const googleAuthProvider = new GoogleAuthProvider();

const facebookAuthProvider = new FacebookAuthProvider();

googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
});

facebookAuthProvider.setCustomParameters({
  prompt: 'select_account'
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const firestoreUtils = {
  getDocument: async docRef => {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id };
    }
    return null;
  },
  getDocuments: async q => {
    const querySnapshot = await getDocs(q);
    const docList = [];
    querySnapshot.forEach(doc => {
      docList.push({ ...doc.data(), id: doc.id });
    });
    return docList;
  }
};

const firestore = {
  /***** /places *****/
  getPlace: placeId => {
    return firestoreUtils.getDocument(doc(FTDB, 'places', placeId));
  },

  getPlacesByUserId: userId => {
    return firestoreUtils.getDocuments(
      query(
        collection(FTDB, 'places'),
        where('users', 'array-contains', userId)
      )
    );
  },

  // ***** /places/{placeId}/automations ****
  getAutomations: placeId => {
    return firestoreUtils.getDocuments(
      query(collection(FTDB, `places/${placeId}/automations`))
    );
  },

  getAutomation: (placeId, automationId) => {
    return firestoreUtils.getDocument(
      doc(FTDB, 'places', placeId, 'automations', automationId)
    );
  },

  addAutomation: (placeId, automation) => {
    return addDoc(
      collection(FTDB, 'places', placeId, 'automations'),
      {
        ...automation,
        actions: [],
        triggers: []
      },
      { merge: true }
    );
  },

  setAutomationName: (placeId, automationId, name) => {
    return setDoc(
      doc(FTDB, 'places', placeId, 'automations', automationId),
      { name },
      { merge: true }
    );
  },

  setTriggersOfAutomation: (placeId, automationId, triggers, triggersType) => {
    return setDoc(
      doc(FTDB, 'places', placeId, 'automations', automationId),
      { triggers, triggers_type: triggersType },
      { merge: true }
    );
  },

  setActionsOfAutomation: (placeId, automationId, actions) => {
    return setDoc(
      doc(FTDB, 'places', placeId, 'automations', automationId),
      { actions },
      { merge: true }
    );
  },

  deleteAutomation: (placeId, automationId) => {
    return deleteDoc(doc(FTDB, 'places', placeId, 'automations', automationId));
  },

  // ***** /devices ****
  getDevice: deviceId => {
    return firestoreUtils.getDocument(doc(FTDB, 'devices', deviceId));
  },

  getDevicesOfPlace: placeId => {
    return firestoreUtils.getDocuments(
      query(
        collection(FTDB, 'devices'),
        where('places', 'array-contains', placeId)
      )
    );
  },

  // ***** /devices/{deviceId}/device-automations ****
  getDeviceAutomations: deviceId => {
    return firestoreUtils.getDocuments(
      query(collection(FTDB, 'devices', deviceId, 'device-automations'))
    );
  },

  getDeviceAutomation: (deviceId, deviceAutomationId) => {
    return firestoreUtils.getDocument(
      doc(FTDB, 'devices', deviceId, 'device-automations', deviceAutomationId)
    );
  },

  addDeviceAutomation: (deviceId, deviceAutomation) => {
    return addDoc(
      collection(FTDB, 'devices', deviceId, 'device-automations'),
      {
        ...deviceAutomation,
        actions: [],
        triggers: []
      },
      { merge: true }
    );
  },

  setDeviceAutomationName: (deviceId, deviceAutomationId, name) => {
    return setDoc(
      doc(FTDB, 'devices', deviceId, 'device-automations', deviceAutomationId),
      { name },
      { merge: true }
    );
  },

  setTriggersOfDeviceAutomation: (
    deviceId,
    deviceAutomationId,
    triggers,
    triggersType
  ) => {
    return setDoc(
      doc(FTDB, 'devices', deviceId, 'device-automations', deviceAutomationId),
      { triggers, triggers_type: triggersType },
      { merge: true }
    );
  },

  setActionsOfDeviceAutomation: (deviceId, deviceAutomationId, actions) => {
    return setDoc(
      doc(FTDB, 'devices', deviceId, 'device-automations', deviceAutomationId),
      { actions },
      { merge: true }
    );
  },

  deleteDeviceAutomation: (deviceId, deviceAutomationId) => {
    return deleteDoc(
      doc(FTDB, 'devices', deviceId, 'device-automations', deviceAutomationId)
    );
  },

  // ***** /users ****
  setUserLanguage: languageCode => {
    return setDoc(
      doc(FTDB, 'users', AUTH.currentUser.uid),
      { language_code: languageCode },
      { merge: true }
    );
  },

  setUserDisplayName: displayName => {
    return setDoc(
      doc(FTDB, 'users', AUTH.currentUser.uid),
      { display_name: displayName },
      { merge: true }
    );
  },

  onUserlineProfileSnapshot: callback => {
    return onSnapshot(doc(FTDB, 'users', AUTH.currentUser.uid), doc => {
      if (doc.exists()) {
        callback({
          line_msg: doc.data()['line_msg'],
          line_msg_state: doc.data()['line_msg_state']
        });
      } else {
        callback(undefined);
      }
    });
  },

  setLineConnectState: state => {
    return setDoc(
      doc(FTDB, 'users', AUTH.currentUser.uid),
      {
        line_msg: {
          state: state
        }
      },
      { merge: true }
    );
  },

  // ***** /telemetry-type ****
  getTelemetryTypes: () => {
    return firestoreUtils.getDocuments(
      query(collection(FTDB, 'telemetry-type'))
    );
  }

  // ---- Deprecated ----
  // getDataListByDeviceId: deviceId => {
  //   return firestoreUtils.getDocuments(
  //     query(collection(FTDB, 'devices', deviceId, 'data-list'))
  //   );
  // },
};

const database = {
  setAutoFlag: (deviceId, portId, flag) => {
    const deviceStatesRef = ref(
      RTDB,
      `deviceStates/${deviceId}/auto/ports/${portId}`
    );
    set(deviceStatesRef, flag);
  },
  getRealtimeDataAllValue: (deviceId, dataTypeId) => {
    const realtimeDataRef = query(
      ref(RTDB, `devices/${deviceId}/${dataTypeId}`),
      orderByKey()
    );
    return get(realtimeDataRef).then(snapshot => {
      const data = snapshot.val();
      return data;
    });
  },
  getRealtimeDataValue: (deviceId, dataTypeId, dataIdx) => {
    const realtimeDataRef = query(
      ref(RTDB, `devices/${deviceId}/${dataTypeId}/${dataIdx}`),
      orderByKey()
    );
    return get(realtimeDataRef).then(snapshot => {
      const data = snapshot.val();
      return data;
    });
  },
  onRealtimeDataValue: (deviceId, dataTypeId, dataIdx, callback) => {
    const realtimeDataRef = ref(
      RTDB,
      `devices/${deviceId}/${dataTypeId}/${dataIdx}`
    );
    return onValue(realtimeDataRef, snapshot => {
      const data = snapshot.val();
      callback(data);
    });
  },
  onRealtimeDataChildAdded: (
    deviceId,
    dataTypeId,
    dataIdx,
    startAfterTime,
    callback
  ) => {
    const realtimeDataRef = query(
      ref(RTDB, `devices/${deviceId}/${dataTypeId}/${dataIdx}`),
      orderByKey(),
      startAfter(`${startAfterTime}`)
    );
    return onChildAdded(realtimeDataRef, snapshot => {
      const time = snapshot.key;
      const value = snapshot.val();
      callback(time, value);
    });
  },
  onDeviceStatesValue: (deviceId, callback) => {
    const deviceStatesRef = ref(RTDB, `deviceStates/${deviceId}`);
    return onValue(deviceStatesRef, snapshot => {
      const data = snapshot.val();
      callback(data);
    });
  }
};

const functions = {
  addDevice: httpsCallable(FUNC, 'device-addDevice'),
  setSwitchManual: httpsCallable(FUNC, 'control-setSwitchManual'),
  // connectLine: httpsCallable(FUNC, 'line-connect'),
  // checkLineStatus: httpsCallable(FUNC, 'line-status'),
  // revokeLine: httpsCallable(FUNC, 'line-revoke'),
  // notifyLine: httpsCallable(FUNC, 'line-notifyFromWeb'),
  generateConnectCode: httpsCallable(FUNC, 'lineMsg-generateConnectCode'),
  notifyLine: httpsCallable(FUNC, 'lineMsg-notifyFromWeb')
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  firestore,
  database
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async user => {
        if (user) {
          const userRef = doc(FTDB, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          AUTH.languageCode = 'en';

          if (docSnap.exists()) {
            const userDetails = docSnap.data();
            setProfile({
              displayName: userDetails?.display_name
            });
            AUTH.languageCode = userDetails.language_code || 'en';
          } else {
            AUTH.languageCode = 'en';
          }

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const loginWithGoogle = () => signInWithPopup(AUTH, googleAuthProvider);

  const loginWithFacebook = () => signInWithPopup(AUTH, facebookAuthProvider);

  const loginWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(AUTH, email, password);

  const register = (email, password, name) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(AUTH, email, password)
        .then(async res => {
          try {
            const userRef = doc(collection(FTDB, 'users'), res.user?.uid);
            await setDoc(userRef, {
              uid: res.user?.uid,
              email,
              username: `${name}`
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  const logout = () => signOut(AUTH);

  const resetPassword = email => sendPasswordResetEmail(AUTH, email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: profile?.displayName || state?.user?.displayName,
          languageCode: state?.user?.auth?.languageCode,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user'
        },
        loginWithEmailAndPassword,
        loginWithGoogle,
        loginWithFacebook,
        register,
        logout,
        resetPassword,
        firestore,
        database,
        functions
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
