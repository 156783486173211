/* eslint-disable no-useless-escape */

import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Table, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import ReactDatePicker from 'react-datepicker';

const TextFieldRow = ({ name, value, isEdit, onChange, htmlSize }) => {
  return (
    <tr className="hover-actions-trigger">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <Form.Control
            className="w-auto ms-auto text-end"
            size="sm"
            type="text"
            step="0.5"
            htmlSize={htmlSize || 2}
            defaultValue={value === undefined ? '' : value}
            onBlur={onChange}
          />
        ) : (
          value
        )}
      </td>
    </tr>
  );
};
TextFieldRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  htmlSize: PropTypes.number
};

const TimePickerRow = ({ name, value, isEdit, onChange, htmlSize }) => {
  if (!value) {
    value = new Date();
  } else {
    value = new Date(value);
  }

  return (
    <tr className="">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <div>
            <ReactDatePicker
              className="w-auto"
              style={{ width: '100%' }}
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              dateFormat="h:mm"
              timeIntervals={5}
              selected={value}
              onChange={onChange}
              timezone="UTC"
              customInput={
                <Form.Control
                  className="ms-auto text-end w-auto"
                  size="sm"
                  type="text"
                  step="0.5"
                  htmlSize={htmlSize || 2}
                />
              }
            />
          </div>
        ) : (
          value.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric'
          })
        )}
      </td>
    </tr>
  );
};
TimePickerRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  htmlSize: PropTypes.number
};

const DropDownRow = ({ name, value, options, isEdit, onChange }) => {
  // console.log('DropDownRowDropDownRowDropDownRow', defaultValue, options);
  return (
    <tr className="hover-actions-trigger">
      <td>{name}</td>
      <td className="text-end">
        {isEdit ? (
          <Form.Select
            size="sm"
            className="w-auto ms-auto text-center"
            value={value}
            style={{}}
            onChange={onChange}
          >
            {options.map(o => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </Form.Select>
        ) : (
          options.filter(o => o.id === value)[0]?.name
        )}
      </td>
    </tr>
  );
};
DropDownRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  isEdit: PropTypes.bool,
  onChange: PropTypes.func
};

const TimeTriggerSettingTable = ({ cronExp, timeframe, isEdit, onChange }) => {
  const defaultValue = '/5 /12 * * * ?';

  const timeframeOptions = [
    { id: 'MINUTES', name: 'Minutes' },
    { id: 'HOURLY', name: 'Hourly' },
    { id: 'DAILY', name: 'Daily' },
    { id: 'WEEKLY', name: 'Weekly' }
  ];

  const daysOfWeek = [
    { id: 'SUN', checked: true },
    { id: 'MON', checked: true },
    { id: 'TUE', checked: true },
    { id: 'WED', checked: true },
    { id: 'THU', checked: true },
    { id: 'FRI', checked: true },
    { id: 'SAT', checked: true }
  ];

  const [daysOfWeekSelected, setDaysOfWeekSelected] = useState(daysOfWeek);

  const _days = [
    daysOfWeekSelected.slice(0, 4),
    daysOfWeekSelected.slice(4, 7)
  ];

  const [_timeframe, setTimeframe] = useState(timeframe || 'SECONDS');
  const [every_, setEvery] = useState(0);

  const parseCron = cron => {
    const cronArr = cron.split(' ');
    //Regex to check if the cron is a repeat cron or not

    const cronObj = {
      second: cronArr[0],
      minute: cronArr[1],
      hour: cronArr[2],
      day: cronArr[3],
      week: cronArr[4],
      month: cronArr[5]
    };

    return cronObj;
  };

  const [cronParsed] = useState(parseCron(cronExp || defaultValue));

  const [dateTime, setDateTime] = useState(new Date());

  const parseCronValToNumber = cronVal => {
    const regx = new RegExp(/\//);
    //Regex to check if the cron is a repeat cron or not

    if (regx.test(cronVal)) {
      return parseInt(cronVal.split('/')[1] || 0);
    } else if (new RegExp(/\*/).test(cronVal)) {
      return 1;
    }
    return parseInt(cronVal);
  };

  const validateCronVal = val => {
    if (val === 1) {
      return '*';
    }
    return '*/' + val;
  };

  const handleChangeTimeframe = e => {
    handleChange({
      timeframe: e.target.value
    });
  };

  const handleChangeEvery = e => {
    handleChange({
      every: e.target.value
    });
  };

  const handleChangeDateTime = date => {
    handleChange({ date: date });
  };

  const handleChangeWeekly = (day, checked) => {
    console.log(day, checked);
    const _daysOfWeekSelected = daysOfWeekSelected.map(d => {
      if (d.id === day.id) {
        return { ...d, checked: checked };
      }
      return d;
    });
    setDaysOfWeekSelected(_daysOfWeekSelected);
    handleChange({ daysOfWeekSelection: _daysOfWeekSelected });
  };

  const handleChange = ({ timeframe, every, date, daysOfWeekSelection }) => {
    let __cronParsed = cronParsed;
    let __every = every_;
    let __timeframe = _timeframe;
    let __date = dateTime;
    let __daysOfWeekSelected = daysOfWeekSelected;
    let __freq = every;
    if (timeframe) {
      setTimeframe(timeframe);
      __timeframe = timeframe;
    } else if (every) {
      setEvery(every);
      __every = every;
    } else if (date) {
      setDateTime(date);
      __date = date;
    } else if (daysOfWeekSelection) {
      setDaysOfWeekSelected(daysOfWeekSelection);
      __daysOfWeekSelected = daysOfWeekSelection;
    }
    let days = '';

    switch (__timeframe) {
      case 'SECONDS':
        __cronParsed = {
          second: validateCronVal(__every),
          minute: __date.getUTCMinutes(),
          hour: __date.getUTCHours(),
          day: '*',
          week: '*',
          month: '?'
        };
        break;
      case 'MINUTES':
        if (parseInt(__freq) > 0) {
          if (parseInt(__freq) === 1) {
            __every = `*`;
          } else {
            __every = `*/${__every}`;
          }
        } else {
          __every = `${__date.getUTCMinutes()}`;
        }
        console.log(__freq);
        __cronParsed = {
          second: '0',
          minute: __every,
          hour: parseInt(__freq) === 0 ? __date.getUTCHours() : '*',
          day: '*',
          week: '*',
          month: '?'
        };
        break;
      case 'HOURLY':
        console.log(__freq);
        if (parseInt(__freq) > 0) {
          if (parseInt(__freq) === 1) {
            __every = `*`;
          } else {
            __every = `*/${__every}`;
          }
        } else {
          __every = `${__date.getUTCHours()}`;
        }
        __cronParsed = {
          second: '0',
          minute: __date.getUTCMinutes(),
          hour: __every,
          day: '*',
          week: '*',
          month: '?'
        };
        break;
      case 'DAILY':
        if (every_ > 0) {
          if (every_ === 1) {
            __every = `*`;
          } else {
            __every = `*/${__every}`;
          }
        } else {
          __every = '*';
        }
        __cronParsed = {
          second: '0',
          minute: __date.getUTCMinutes(),
          hour: __date.getUTCHours(),
          day: __every,
          week: '*',
          month: '?'
        };
        break;
      case 'WEEKLY':
        // Handle weekly case if needed
        //0 15 10 ? * MON-FRI
        __daysOfWeekSelected.forEach(day => {
          if (day.checked) {
            days += `${day.id},`;
          }
        });
        days = days.slice(0, -1);
        __cronParsed = {
          second: '0',
          minute: __date.getUTCMinutes(),
          hour: __date.getUTCHours(),
          day: '?',
          week: '*',
          month: days
        };
        break;
      default:
        break;
    }

    console.log(toCronExp(__cronParsed));
    onChange({ timeframe: _timeframe, cronExp: toCronExp(__cronParsed) });
  };

  //function to cron parsed to cron string
  const toCronExp = object => {
    return `${object.second} ${object.minute} ${object.hour} ${object.day} ${object.week} ${object.month}`;
  };

  useEffect(() => {
    console.log('cronParsed', cronParsed);
    switch (_timeframe) {
      case 'SECONDS':
        setEvery(parseCronValToNumber(cronParsed.second));
        break;
      case 'MINUTES':
        setEvery(parseCronValToNumber(cronParsed.minute));
        break;
      case 'HOURLY':
        console.log(parseCronValToNumber(cronParsed.hour));
        console.log(cronParsed.hour);
        setEvery(
          parseCronValToNumber(
            cronParsed.hour.match(/[*\/]/g)
              ? parseCronValToNumber(cronParsed.hour)
              : parseCronValToNumber(cronParsed.hour) > 1
              ? 1
              : 0
          )
        );
        try {
          setDateTime(
            new Date().setUTCHours(
              cronParsed.hour.match(/[*\/]/g)
                ? new Date().getUTCHours()
                : cronParsed.hour,
              cronParsed.minute,
              0
            )
          );
        } catch (e) {
          console.log(e);
          setDateTime(new (new Date().setUTCMinutes(cronParsed.minute))());
        }
        break;
      case 'DAILY':
        setEvery(parseCronValToNumber(cronParsed.day));
        setDateTime(
          new Date(new Date().setUTCHours(cronParsed.hour, cronParsed.minute))
        );

        break;
      case 'WEEKLY':
        setDaysOfWeekSelected(
          daysOfWeekSelected.map(day => {
            return {
              ...day,
              checked: cronParsed.month.includes(day.id) //day.id
            };
          })
        );
        setDateTime(
          new Date(new Date().setUTCHours(cronParsed.hour, cronParsed.minute))
        );
        break;
      default:
        break;
    }
  }, [cronParsed]);

  return (
    <>
      <DropDownRow
        isEdit={isEdit}
        value={_timeframe || 'Seconds'}
        name={'Timeframe'}
        options={timeframeOptions}
        onChange={handleChangeTimeframe}
      />
      {_timeframe === 'SECONDS' && (
        <>
          <TextFieldRow
            isEdit={isEdit}
            name={'Repeat Every (Seconds)'}
            value={every_}
            onChange={handleChangeEvery}
          />
        </>
      )}
      {_timeframe === 'MINUTES' && (
        <>
          <TextFieldRow
            isEdit={isEdit}
            name={'Repeat Every (Minutes)'}
            value={every_}
            onChange={handleChangeEvery}
          />
        </>
      )}
      {_timeframe === 'HOURLY' && (
        <>
          <TextFieldRow
            isEdit={isEdit}
            name={'Repeat Every (Hours)'}
            value={every_}
            onChange={handleChangeEvery}
          />
          <TimePickerRow
            htmlSize={2}
            isEdit={isEdit}
            name={'Time'}
            value={dateTime}
            onChange={handleChangeDateTime}
          />
        </>
      )}
      {_timeframe === 'DAILY' && (
        <>
          <TextFieldRow
            isEdit={isEdit}
            name={'Repeat Every'}
            value={every_}
            onChange={handleChangeEvery}
          />

          <TimePickerRow
            htmlSize={2}
            isEdit={isEdit}
            name={'Time'}
            value={dateTime}
            onChange={handleChangeDateTime}
          />
        </>
      )}
      {_timeframe === 'WEEKLY' && (
        <>
          <tr className="hover-actions-trigger">
            <td>Every</td>
            <td>
              {_days.map((item, i) => {
                return (
                  <Flex justifyContent="end" key={i}>
                    {item.map((day, j) => {
                      return (
                        <Form.Check
                          key={i + j}
                          className="d-flex align-items-center"
                          style={{ marginLeft: '12px' }}
                          id={`todoItem-${i + j}`}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            disabled={!isEdit}
                            checked={day.checked}
                            onChange={e =>
                              handleChangeWeekly(day, e.target.checked)
                            }
                            className={classNames(
                              `mt-0 rounded-circle form-check-input-red`
                            )}
                          />
                          <Form.Check.Label className="flex-1 mb-0 ms-1">
                            {day.id}
                          </Form.Check.Label>
                        </Form.Check>
                      );
                    })}
                  </Flex>
                );
              })}
            </td>
          </tr>

          <TimePickerRow
            htmlSize={2}
            isEdit={isEdit}
            name={'Time'}
            value={dateTime}
            onChange={handleChangeDateTime}
          />
        </>
      )}
    </>
  );
};

TimeTriggerSettingTable.propTypes = {
  cronExp: PropTypes.string,
  timeframe: PropTypes.string,
  isEdit: PropTypes.bool,
  onChange: PropTypes.func
};

const PortSettingTable = ({
  type,
  portIdx,
  isEdit,
  params,
  device,
  onChange
}) => {
  const { t } = useTranslation();
  const [portList, setPortList] = useState(undefined);

  const getPortListAndPortIdx = defaultPortIdx => {
    if (device) {
      console.log(device.n_ports);
      const nPorts = device.n_ports;
      if (nPorts > 0) {
        var list = [];
        for (var i = 0; i < nPorts; i++) {
          list.push(i);
        }
        return {
          portList: list,
          portIdx: defaultPortIdx < nPorts ? defaultPortIdx : 0
        };
      }
    }
    return { portList: undefined, portIdx: undefined };
  };

  useEffect(() => {
    console.log('device', device);
    // if (!isDeviceHasPort(device)) {
    //   const devices = devicesOfPlace?.filter(d => isDeviceHasPort(d));
    //   onChange({ device_id: devices[0]?.id });
    //   return;
    // }
    // if (deviceId === undefined) {
    //   onChange({ device_id: devicesOfPlace?.[0]?.id });
    //   return;
    // }
    const { portList: newPortList, portIdx: newPortIdx } =
      getPortListAndPortIdx(portIdx);
    setPortList(newPortList);

    let changeObj = {};
    if (portIdx !== newPortIdx) changeObj = { port: newPortIdx };
    if (params === undefined) {
      changeObj = { ...changeObj, params: [0, 0] };
    }
    if (Object.keys(changeObj).length > 0) {
      onChange(changeObj);
    }
  }, [device]);

  // console.log('portList', portList,portIdx);
  return (
    <>
      {portList && (
        <DropDownRow
          isEdit={isEdit}
          name={t('port')}
          value={portIdx}
          options={portList?.map(portIdx => ({
            id: portIdx,
            name: `${portIdx + 1}`
          }))}
          onChange={e => onChange({ port: parseInt(e.target.value) })}
        />
      )}
      {type === 'port-turn-toggle-on' && (
        <TextFieldRow
          isEdit={isEdit}
          name={`${t('on')} (${t('sec')})`}
          value={params?.[0]}
          onChange={e =>
            onChange({ params: [parseInt(e.target.value), params?.[1]] })
          }
        />
      )}
      {type === 'port-turn-toggle-on' && (
        <TextFieldRow
          isEdit={isEdit}
          name={`${t('off')} (${t('sec')})`}
          value={params?.[1]}
          onChange={e =>
            onChange({ params: [params?.[0], parseInt(e.target.value)] })
          }
        />
      )}
    </>
  );
};
PortSettingTable.propTypes = {
  isEdit: PropTypes.bool,
  device: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.string,
  params: PropTypes.array,
  deviceId: PropTypes.string,
  portIdx: PropTypes.number
};

const LineNotifyTable = ({ text, isEdit, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextFieldRow
        htmlSize={25}
        isEdit={isEdit}
        name={`${t('message')}`}
        value={text}
        onChange={e => onChange({ text: e.target.value })}
      />
    </>
  );
};
LineNotifyTable.propTypes = {
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string
};

const settingTypes = {
  time: 'TimeTriggerSettingTable',
  'port-turn-on': 'PortSettingTable',
  'port-turn-off': 'PortSettingTable',
  'port-turn-toggle-on': 'PortSettingTable',
  port: 'PortTriggerSettingTable'
};

const SettingView = ({
  title,
  setting,
  isEdit,
  device,
  telemetryTypes,
  supportedSettingTypes,
  onChange,
  onRemove
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (setting.type === undefined) {
      //defualt value
      onChange({ type: supportedSettingTypes[0] });
    }
  }, [setting]);

  return (
    <>
      <FalconCardHeader
        title={title}
        className="bg-soft-secondary text-center p-2 rounded-0"
        titleTag="h6"
        endEl={
          isEdit ? (
            <IconButton
              variant="link"
              className="text-danger fs--1 py-0 m-0"
              size="sm"
              icon="trash"
              iconAlign="left"
              onClick={onRemove}
            >
              Remove
            </IconButton>
          ) : undefined
        }
      />
      <Table hover responsive borderless size="sm">
        <tbody>
          <DropDownRow
            isEdit={isEdit}
            name={t('type')}
            value={setting.type}
            options={supportedSettingTypes.map(type => ({
              id: type,
              name: t(type)
            }))}
            onChange={e => onChange({ type: e.target.value })}
          />
          {settingTypes[setting.type] === 'TimeTriggerSettingTable' && (
            <TimeTriggerSettingTable
              timeframe={setting.timeframe}
              cronExp={setting.cron_exp}
              deviceId={setting.device_id}
              device={device}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}

          {settingTypes[setting.type] === 'PortSettingTable' && (
            <PortSettingTable
              type={setting.type}
              deviceId={setting.device_id}
              portIdx={setting.port}
              params={setting.params}
              device={device}
              telemetryTypes={telemetryTypes}
              isEdit={isEdit}
              onChange={obj => {
                onChange({ ...obj });
              }}
            />
          )}
        </tbody>
      </Table>
    </>
  );
};
SettingView.propTypes = {
  trigger: PropTypes.object,
  isEdit: PropTypes.bool,
  setting: PropTypes.object,
  supportedSettingTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.object,
  title: PropTypes.string
};

export const SettingsList = ({
  title,
  subTitle,
  isLoading,
  hasTypeField,
  defaultSettings,
  defaultType,
  device,
  telemetryTypes,
  supportedSettingTypes,
  onSave
}) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [settingsList, setSettingsList] = useState(defaultSettings);
  const [settingsType, setSettingsType] = useState(defaultType);
  // const navigate = useNavigate();

  return (
    <>
      {settingsList && (
        <>
          <Card className={`overflow-hidden`} style={{ position: 'unset' }}>
            <FalconCardHeader
              title={title}
              className="bg-soft-light"
              titleTag="h5"
              endEl={
                hasTypeField ? (
                  isEdit ? (
                    <Form.Select
                      size="sm"
                      className="me-2"
                      value={settingsType}
                      onChange={e => {
                        setSettingsType(e.target.value);
                      }}
                    >
                      <option value="any">{t('triggersTypeAny')}</option>
                      <option value="all">{t('triggersTypeAll')}</option>
                    </Form.Select>
                  ) : settingsType == 'all' ? (
                    t('triggersTypeAll')
                  ) : (
                    t('triggersTypeAny')
                  )
                ) : undefined
              }
            />
            {settingsList.map((setting, i) => {
              // console.log(i,defaultTriggers,triggers,trigger)
              return (
                <SettingView
                  title={`${subTitle} ${i + 1}`}
                  setting={setting}
                  device={device}
                  telemetryTypes={telemetryTypes}
                  supportedSettingTypes={supportedSettingTypes}
                  isEdit={isEdit}
                  key={i}
                  onChange={e => {
                    const newSettingsList = settingsList.slice();
                    newSettingsList[i] = { ...newSettingsList[i], ...e };
                    console.log('newSettingsList', newSettingsList[i]);
                    setSettingsList(newSettingsList);
                  }}
                  onRemove={() => {
                    const newSettingsList = settingsList
                      .slice()
                      .filter((t, idx) => idx != i);
                    setSettingsList(newSettingsList);
                  }}
                />
              );
            })}
            <Card.Footer className="row text-left bg-soft-light border-top">
              {isEdit ? (
                <>
                  <div className="col">
                    <IconButton
                      variant="outline-primary"
                      size="sm"
                      icon="plus"
                      iconAlign="left"
                      onClick={() => {
                        const newSettingsList = settingsList.slice();
                        newSettingsList.push({});
                        setSettingsList(newSettingsList);
                      }}
                    >
                      {t('add')}
                    </IconButton>
                  </div>
                  <div className="text-right col-auto">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => {
                        // console.log(defaultTriggers);
                        setSettingsList(defaultSettings);
                        setSettingsType(defaultType);
                        setIsEdit(false);
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="ms-2"
                      onClick={() => {
                        setIsEdit(false);
                        onSave(settingsList, settingsType);
                      }}
                    >
                      {t('save')}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col"></div>
                  <div className="text-right col-auto">
                    <IconButton
                      variant="primary"
                      size="sm"
                      className="ms-2"
                      icon="edit"
                      iconAlign="left"
                      onClick={() => setIsEdit(true)}
                    >
                      {t('edit')}
                    </IconButton>
                  </div>
                </>
              )}
            </Card.Footer>
            {isLoading && (
              <div className="position-absolute d-flex justify-content-center align-items-center bg-light w-100 h-100 opacity-75">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </Card>
        </>
      )}
    </>
  );
};

SettingsList.propTypes = {
  defaultSettings: PropTypes.arrayOf(PropTypes.object),
  defaultType: PropTypes.string,
  onSave: PropTypes.func,
  supportedSettingTypes: PropTypes.arrayOf(PropTypes.string),
  telemetryTypes: PropTypes.arrayOf(PropTypes.object),
  device: PropTypes.object,
  hasTypeField: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string
};
